<template>
  <div class="swiper-container mySwiper">
    <div class="swiper-wrapper">
      <!-- 存放具体的轮播内容 -->
      <slot></slot>
    </div>
    <!-- 如果需要分页器 -->
    <div v-if="setting.usePagination || true" class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <div v-if="setting.useSwiperBtn || false" class="swiper-button-prev"></div>
    <div v-if="setting.useSwiperBtn || false" class="swiper-button-next"></div>
    <!-- 如果需要滚动条 -->
    <div v-if="setting.useSwiperScroll || false" class="swiper-scrollbar"></div>
  </div>
</template>

<script>
import { Swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: ["swiperSetting"],
  data() {
    return {
      setting: {},
    };
  },
  mounted() {
    // 判断用户是否做了设置项，做了就传进来，没做的话就全部按照开发的默认配置项设置
    this.setting = this.swiperSetting ? this.swiperSetting : {};
  },
  updated() {
    new Swiper(".mySwiper", {
      loop: this.setting.loop || true, //是否循环
      spaceBetween: this.setting.spaceBetween || 30,
      centeredSlides: this.setting.centeredSlides || true,
      delay: this.setting.delay || 1500,
      disableOnInteraction: this.setting.disableOnInteraction || true,
      // 自动播放轮播
      // 左右导航
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      //分页器
      pagination: {
        el: ".swiper-pagination",
        type:"bullets",
      },
      //方向
      direction: this.setting.direction || "horizontal",
      //特效
      effect: this.setting.effect || "slide",
      //修改swiper自己或子元素时，自动初始化swiper
      observer: true,
      //修改swiper的父元素时，自动初始化swiper
      observeParents: true,
    });
  },
  methods: {},
};
</script>

<style  scoped>
.swiper-container {
  width: 730px;
  height: 390px;
}
</style>
