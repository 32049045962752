<template>
  <div class="container">
    <div class="content">
      <div class="head">
        <div class="head_left">
          <div class="head_text" @click="goTo(3)">商城首页</div>
          <div>您好，欢迎来到{{ information.title }}商城</div>
        </div>
        <div class="head_right" v-if="Object.keys(userInfo).length == 0">
          <div class="head_text" @click="goTo(1)">您好，请登录</div>
          <div class="head_text" @click="goTo(2)">免费注册</div>
        </div>
      </div>
      <div class="content_head">
        <div class="logo">
          <img @click="goTo(3)" :src="information.cover" alt="">
          <div style="margin-left: 20px;font-size: 30px;font-weight: bold;">{{ information.title }}商城</div>
        </div>
        <div class="search">
          <div class="search_r">
            <ul>
              <li @click="checked(0)" :class="isShop ? 'active' : ''">宝贝</li>
              <li @click="checked(1)" :class="!isShop ? 'active' : ''">店铺</li>
            </ul>
            <input type="text" v-model="keyword" oninput="this.value=this.value.replace(/\s+/g,'')" placeholder="请输入关键词">
          </div>
          <div @click="search(isShop)">搜索</div>
          <div class="merlist" @click="getMer">商家列表</div>
        </div>
      </div>
      <hr color="#c20909" />
      <slot></slot>
    </div>
    <div class="footer">
      <ul class="footer_list">
        <li><span class="link" @click="aboutGo">关于我们</span></li>
        <li style="position:relative">
          <span class="link" @click="tel">联系我们</span>
          <div class="headPop-text" v-if="telShow" @click="copyData">18704601568</div>
        </li>
        <li><a class="link" href="http://douhuofalv.cn/recruitment">商家入驻</a></li>
        <li><span class="link" @click="cultureGo">企业文化</span></li>
        <li><span class="link" @click="helpGo">帮助中心</span></li>
      </ul>
      <div>
        <a style="color: #fff;" href="https://beian.miit.gov.cn/#/Integrated/index">{{ information.record_code }}</a>
        {{ information.address }}
      </div>
    </div>
  </div>
</template>
<script>
// import {
//   message
// } from 'ant-design-vue'
import api from '@/api/api.js'
export default {
  name: 'Container-Index',
  components: {},
  data () {
    return {
      isShop: true,
      keyword: '',
      information: {},
      userInfo: {},
      telShow : false,
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
      api.getRecord().then(res => {
        this.information = res
      })
    },
    goTo (state) {
      if (state === 1) {
        this.$router.push({
          path: './login'
        })
      }
      if (state === 2) {
        this.$router.push({
          path: './register'
        })
      }
      if (state === 3) {
        this.$router.push({
          path: './'
        })
      }
    },
    search (status) {
      if (this.keyword === '') {
        if (status) {
          api.goodsList().then(res => {
            sessionStorage.setItem('searchGood', JSON.stringify(res))
            this.$router.push({
              path: './shopList',
              query: {
                is: true
              }
            })
          })
        } else {
          this.getMer()
        }
      } else {
        if (status) {
          api.goodsList({
            name: this.keyword
          }).then(res => {
            sessionStorage.setItem('searchGood', JSON.stringify(res))
            this.$router.push({
              path: './shopList',
              query: {
                is: true
              }
            })
          })
        } else {
          api.getMer({
            name: this.keyword
          }).then(res => {
            sessionStorage.setItem('searchMer', JSON.stringify(res))
            this.$router.push({
              path: './merList'
            })
          })
        }
      }
    },
    checked (state) {
      if (state === 0) {
        this.isShop = true
      } else {
        this.isShop = false
      }
    },
    getMer () {
      api.getMer().then(res => {
        sessionStorage.setItem('searchMer', JSON.stringify(res))
        this.$router.push({
          path: './merList'
        })
      })
    },

    tel() {
      this.telShow = !this.telShow
    },

    aboutGo () {
      this.$router.push({
        path: './about'
      })
    },

    cultureGo () {
      this.$router.push({
        path: './culture'
      })
    },
    
    helpGo () {
      this.$router.push({
        path: './help'
      })
    },
  }
}
</script>
<style scoped>
.container {
  min-height: 100vh;
  position: relative;
}
.content{
  min-height: calc(100vh - 200px);
}
.head {
  display: flex;
  justify-content: space-between;
  background-color: rgb(226, 226, 226);
  padding: 15px 100px;
  color: #999;
  box-sizing: border-box;
}

.head_left {
  display: flex;
}

.head_right {
  display: flex;
}

.head_text {
  margin: 0 20px;
}

.content_head {
  display: flex;
  width: 1210px;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 1%;
  align-items: center;
}

.logo {
  width: 300px;
  display: flex;
  align-items: center;
}

.logo img {
  color: #fff;
  width: 100px;
  height: 100px;
  /* line-height: 80px; */
}

.search {
  height: 80px;
  display: flex;
  align-items: center;
}

.search_r {
  position: relative;
}

.search_r input {
  height: 50px;
  padding-left: 10px;
  border-radius: 30px 0 0 30px;
  width: 300px;
  border: rgb(194, 9, 9) 2px solid;
  outline: none;
}

.search div:nth-child(2) {
  background-color: rgb(194, 9, 9);
  color: #fff;
  height: 50px;
  line-height: 50px;
  width: 100px;
}

.search_r ul {
  display: flex;
  position: absolute;
  top: -22px;
  left: 30px;
  align-items: center;
}

.search_r ul li {
  font-size: 12px;
  width: 45px;
}

.active {
  background-color: rgb(194, 9, 9);
  color: #fff;
  padding: 5px 0;
}

.search_r ul li:hover {
  cursor: pointer;
}

.head_text:hover {
  color: rgb(194, 9, 9);
  cursor: pointer;
}

hr {
  margin-top: 20px;
}

.footer {
  height: 120px;
  margin-top: 80px;
  background-color: rgb(194, 9, 9);
  color: #fff;
  padding-top: 5px;
}

.footer div {
  margin-top: 20px;
}

.footer_list {
  display: flex;
  color: #fff;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.footer_list li .link {
  color: #fff;
  text-decoration: none;
  margin: 10px 16px;
  position: relative;
  font-size: 12px;
  cursor: pointer;
}

.footer_list li .link::after {
  content: '';
  position: absolute;
  border-right: 1px solid #fff;
  height: 100%;
  right: -16px;
  top: .5px;

}

.footer_list li:last-child .link::after {
  border: none;
}

.merlist {
  margin-left: 20px;
  color: #fff;
  background-color: rgb(194, 9, 9);
  padding: 10px;
}

.merlist:hover {
  cursor: pointer;
}

.headPop-text {
    position: absolute;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    top: -60px;
    left: -25px;
    z-index: 9;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    box-sizing: border-box;
}
</style>
