import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Index from '../views/index.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: Index
  },
  { //登录
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  { //注册
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/register.vue')
  },
  { //商品详情
    path: '/shopdetail',
    name: 'shopDetail',
    component: () => import('../views/detail/shop.vue')
  },
  { //商家详情
    path: '/merdetail',
    name: 'merDetail',
    component: () => import('../views/detail/mer.vue')
  },
  { //商家列表
    path: '/merList',
    name: 'merList',
    component: () => import('../views/list/merList.vue')
  },
  { //商品列表
    path: '/shopList',
    name: 'shopList',
    component: () => import('../views/list/shopList.vue')
  },
  { //商家入驻
    path: '/recruitment',
    name: 'Recruitment',
    component: () => import('../views/Recruitment/Recruitment.vue')
  },
  { //注册协议 隐私条款
    path: '/agreement/:id',
    name: 'Agreement',
    component: () => import('../views/login/agreement.vue')
  },
  { //关于我们
    path: '/about',
    name: 'about',
    component: () => import('../views/list/about.vue')
  },
  { //企业文化
    path: '/culture',
    name: 'culture',
    component: () => import('../views/list/culture.vue')
  },
  { //帮助中心
    path: '/help',
    name: 'help',
    component: () => import('../views/list/help.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
