import { createStore } from 'vuex'
import { message } from 'ant-design-vue'
export default createStore({
  state: {
    userInfo:{},
  },
  getters: {
  },
  mutations: {
    saveLogin (state, resultData) {
        const reslut = localStorage.setItem("userInfo",JSON.stringify(resultData))
        return state.userInfo =reslut
    },
    getLogin(state){
      const loginInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(loginInfo) return state.userInfo = loginInfo
    },
    logOut(state){
      state.userInfo = {}
      localStorage.clear()
      sessionStorage.clear()
      message.success('退出登录成功')
    },
    saveClassfy(state,arr){
      sessionStorage.setItem("classfy",JSON.stringify(arr))
    }
  },
  actions: {
  },
  modules: {
  }
})
