<template>
  <Container>
    <div class="home">
      <div class="content">
        <div class="content_card">
          <div class="content_card_left">
            <div class="list">
              <div class="list_title">商品分类</div>
                <ul class="list_name">
                    <li @click="goShoplist(item)" v-for="item in classifyList" :key="item.category_id">{{ item.name }} </li>
                </ul>
            </div>
          </div>
          <div>
            <div class="shop_banner_img">
              <MySwiper :swiperSetting="swiperSetting">
                <div v-for="item in dataList" :key="item.id" class="swiper-slide">
                  <a href="#"><img :src="item.cover" alt=""></a>
                </div>
              </MySwiper>
            </div>
          </div>
          <div class="content_card_right">
            <div class="myself">
              <img class="card_right_img" :src="userInfo.avatar ? userInfo.avatar: err" alt="">
              <div class="myself_title">
                {{ Object.keys(userInfo).length > 0 ? 'Hi!'+ userInfo.nickname  : 'Hi！你好' }}
              </div>

              <div v-if="Object.keys(userInfo).length > 0" class="myself_body">
                <div>积分：{{userInfo.score}}</div>
                <div>{{userInfo.name}}</div>
              </div>
              <div class="myself_button" v-else>
                <div class="login" @click="goTo(1)">登录</div>
                <div class="register" @click="goTo(2)">注册</div>
              </div>
              <div class="logout" v-if="Object.keys(userInfo).length > 0">
                <div @click="logOut" class="logout_btn">退出登录</div>
              </div>
              <!-- 商家入驻 -->
              <div class="myself_bottom">
                <div class="merchant" @click="gotoRec">申请商家入驻</div>
                <!-- <div class="merLogin" v-if="Object.keys(userInfo).length == 0" @click="goTo(1)">商家登录</div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="recom">
          <div class="recom_title">推荐商品</div>
          <Shop v-if="goodsList.length > 10" :goodsList="goodsList"></Shop>
        </div>
      </div>
    </div>
  </Container>
</template>
<script>
import Container from '@/components/container.vue'
import Shop from '@/components/shop.vue'
import MySwiper from '@/components/Swiper.vue'
import {
  mapState,
  mapMutations
} from 'vuex'
// import {
//   message
// } from 'ant-design-vue'
import api from '@/api/api.js'
export default {
  name: 'Home-Index',
  components: {
    Container,
    Shop,
    MySwiper
  },
  data () {
    return {
      suc: require('../assets/img/p.jpg'),
      err: require('../assets/img/1.jpeg'),
      dataList: [],
      classifyList: [], // swiper的配置项
      goodsList: [],
      swiperSetting: {
        loop: true, // 是否循环
        spaceBetween: 30, // slide之间设置距离
        centeredSlides: true, // 设定为true时，active slide会居中
        delay: 2000, // 自动播放的时间间隔设置
        usePagination: true, // 是否使用分页器
        useSwiperBtn: true, // 是否使用左右导航按钮
        useSwiperScroll: false, // 是否使用滚动
        disableOnInteraction: true, // 用户操作swiper之后，是否禁止autoplay
        paginationType: 'fraction', // 分页器样式类型
        direction: 'horizontal', // 轮播方向设置
        effect: 'slide' // 切换动画效果设置
      },
      selectedKeys: [],
      openKeys: [],
      information: {}
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  watch: {
    $route (to, from) {
      if (to.name === 'home') {
        this.init()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapMutations(['getLogin', 'logOut', 'saveClassfy']),
    init () {
      api.getMallInfo().then(res => {
        this.classifyList = res.categories
        this.goodsList = res.goods
        this.dataList = res.banners
      }).catch(err => {
        console.log(err)
      })
      this.getLogin()
    },
    goTo (state) {
      if (state === 1) {
        this.$router.push({
          path: './login'
        })
      } else {
        this.$router.push({
          path: './register'
        })
      }
    },
    gotoRec () {
      this.$router.push({
        path: './recruitment'
      })
    },
    goShoplist (item) {
      this.saveClassfy(item.children)
      this.$router.push({
        path: './shopList',
        query: {
          goods: item.category_id
        }
      })
    }
  }
}
</script>
<style scoped>
  .content_card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content_card_left {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .content_card {
    padding: 10px 0;
  }

  .shop_banner_img {
    width: 730px;
  }

  .shop_banner_img img {
    width: 730px;
    height: 390px;
    border-radius: 20px;
  }

  .list {
    /* background-color: rgb(194, 9, 9); */
    /* color: #fff; */
    border: 1px solid rgb(194, 9, 9);
    padding: 10px 0;
    width: 180px;
    border-radius: 20px;
    height: 400px;
  }

  .list_title {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    margin-left: 20px;
    margin-top: 20px;

  }

  .list_name {
    margin-top: 20px;
  }

  .list_name li {
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 3px;
    text-align: left;
    margin-left: 20px;
    cursor: pointer;
  }

  .list_name li:hover {
    color: rgb(194, 9, 9);
    font-size: 18px;
  }

  .content_card_right {
    margin-left: 50px;
    background-color: rgb(245, 245, 245);
    width:250px;
    border-radius: 20px;
    height: 400px;
    padding: 20px 0;
  }

  .card_right_img {
    width: 100px;
    border-radius: 50%;
    border: 1px solid rgb(194, 9, 9);
    margin-top: 20px;
  }
  .myself{
    position: relative;
    height: 100%;
  }

  .myself_title {
    margin-top: 20px;
    font-size: 18px;
  }

  .myself_body {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: 300;
  }

  .myself_body div {
    margin: 0 10px;
  }

  .logout {
    text-align: center;
  }

  .logout_btn {
    color: #fff;
    padding: 10px;
    background-color: rgb(194, 9, 9);
    cursor: pointer;
    border-radius: 20px;
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .recom {
    width: 1210px;
    margin: 0 auto;
  }

  .recom_title {
    margin: 20px 6% 10px;
    text-align: left;
    color: rgb(194, 9, 9);
    font-size: 18px;
    font-weight: bold;
  }

  .myself_button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .myself_button .login,
  .myself_button .register {
    margin: 0 10px;
    cursor: pointer;
  }

  .myself_button .login:hover,
  .myself_button .register:hover {
    color: rgb(194, 9, 9);
  }

  .myself_bottom {
    margin-top: 20px;
  }

  .merchant {
    background-color: #fff;
    border: 1px solid rgb(194, 9, 9);
    width: 140px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .merchant:hover {
    color: #fff;
    background-color: rgb(194, 9, 9);
    cursor: pointer;
    border: 1px solid #fff;
  }

  .merLogin {
    font-size: 14px;
    margin-top: 10px;
    color: #aaa;
    cursor: pointer;
  }

  .merLogin:hover {
    color: rgb(194, 9, 9);
  }
</style>
