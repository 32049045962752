<template>
  <div class="shop">
    <div @click="goTodetail(item.goods_id)" class="shop_item" v-for="item in goodsList" :key="item.goods_id">
      <img class="shop_img" :src="item.cover ? item.cover : require('@/assets/img/test4.jpg')" alt="">
      <div class="shop_name">
        {{item.name}}
      </div>
      <div class="shop_item_bottom">
        <div class="shop_price">¥{{item.original_price}}</div>
        <div class="shop_buy">{{item.price.score}}积分</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Detail-Index',
  props: {
    goodsList: Array
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    goTodetail (id) {
      this.$router.push({
        path: './shopdetail',
        query: {
          id
        }
      })
    }
  }
}
</script>
<style scoped>
.shop{
  display: flex;
  flex-wrap: wrap;
}
.shop_item{
  width: 222px;
  margin: 10px 10px;
}
.shop_img{
  width: 200px;
  height: 200px;
  border-radius: 15px;
}
.shop_name{
  width: 100%;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 10px;
  line-height: 23px;
  height: 45px;

}
.shop_item_bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

}
.shop_price{
  color: rgb(194, 9, 9);
  font-size: 22px;
}
.shop_buy{
  color: #bbb;
  margin-left: 10px;
  font-size: 15px;
}
</style>
