import {
  ajax
} from './axios.js'

const api = {
  url: process.env.VUE_APP_URL,
  getRegister (data) {
    const Url = this.url + '/api/user/auth/register'
    return ajax.post(Url, data)
  },
  getLogin (data) {
    const Url = this.url + '/api/user/auth/login'
    return ajax.post(Url, data)
  },
  getUserInfo (data) {
    const Url = this.url + '/api/user'
    return ajax.get(Url, data)
  },
  getMallInfo (data) {
    const Url = this.url + '/api/mall'
    return ajax.get(Url, data)
  },
  goodsDetail (data) {
    const Url = this.url + '/api/mall/goods/' + data
    return ajax.get(Url)
  },
  goodsList (data) {
    const Url = this.url + '/api/mall/goods'
    return ajax.get(Url, data)
  },
  getRecord (data) {
    const Url = this.url + '/api/records/' + process.env.VUE_APP_RECORD
    return ajax.get(Url)
  },
  getMer (data) {
    const Url = this.url + '/api/mall/shops'
    return ajax.get(Url, data)
  },
  getShopInfo (data) {
    const Url = this.url + '/api/mall/shops/' + data
    return ajax.get(Url)
  },
  getCategories (data) {
    const Url = this.url + '/api/mall/categories'
    return ajax.get(Url)
  },
  aboutUs (data) {
    const Url = this.url + '/api/cms/pages/1'
    return ajax.get(Url)
  },
  cultureUs (data) {
    const Url = this.url + '/api/cms/pages/3'
    return ajax.get(Url)
  },
  helpUs (data) {
    const Url = this.url + '/api/cms/pages/4'
    return ajax.get(Url)
  }
}
export default api
