import axios from 'axios'; // 引入axios
import qs from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到

axios.defaults.timeout = 10000
const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    "Accept" : "application/json"
};
axios.defaults.withCredentials = false;
const http = axios.create({
    headers
});
http.interceptors.request.use(
  config => {
   if(localStorage.getItem('token')){
     config.headers['Authorization']  = localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')
   }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(function (response) {

    if ( !response.data )
    return Promise.reject( { status: 500, message:'服务器繁忙! 请及时反馈给技术支持'} );
    if (response.data.errors) return Promise.resolve(response.data)
    if(response.data) return Promise.resolve(response.data)
     return response.data
}, function (error) {
  return axios.request(error)
	let ErrMsg = error.message || error.msg || error;
	if ( ErrMsg ) {
		if (/code\s*500/i.test(ErrMsg)) {
			return Promise.reject('服务器累瘫了! 请及时反馈给技术支持');
		} else if (/Network/i.test(ErrMsg)) {
			return Promise.reject('您的设备没有网络 请稍后再试吧');
		} else {
			return Promise.reject('服务器繁忙! 请及时反馈给技术支持');
		}
	}
})


export const ajax = {
  post(url, data) {
    return new Promise((resolve, reject) => {
      http({
          method: "post",
          url: url,
          data: data ? qs.stringify(data) : "",
          responseType: "json"
      }).then(response => {
          resolve(response);
      })
      .catch(error => {
          reject(error);
      });
    });
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
        http({
            method: "get",
            url,
            responseType: "json",
            params: data ? data : {}
        }).then(response => {
            resolve(response.data);
        })
        .catch(error => {
            console.log("error", error)
            reject(error);
        });
    });
  }
  
}
